import React from 'react'
import "../scss/components/banner.scss"

const Banner = () => {
  return (
    <>
      <div className="banner">
        <h4>Web制作のご依頼・ご相談などはチームFumpまで</h4>
        <span>いつでも気軽にお問い合わせください</span>
        <div className="banner_link">
          <a href="https://fump.tech/contact">お問い合わせ</a>
        </div>
        <span>＊Twitterの<a href="https://twitter.com/kentaro_koga" target="_blank" rel="noopener noreferrer">DM</a>でもOKです^ ^</span>
      </div>
    </>
  )
}

export default Banner