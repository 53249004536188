import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout.js"
import SEO from "../components/seo"
import Bio from "../components/bio"
import Banner from "../components/banner"

import "../scss/pages/profile.scss"
import "../scss/pages/pageCommon.scss"

const Profile = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressPage(slug: { eq: "profile" }) {
          title
          excerpt
          content
          date(formatString: "YYYY.MM.DD")
          modified(formatString: "YYYY.MM.DD")
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Layout location={location} crumbLabel={data.wordpressPage.title}>
          <SEO title="プロフィール" description={data.wordpressPage.excerpt} />
          <div className="blogPageTopWrap">
            <section className="profileBio" id="profileBio">
              <h2 className="pageSectionTitle">
                <span className="pageSectionTitle_l">About</span>
                <span className="pageSectionTitle_s">僕について</span>
              </h2>
              <Bio />
            </section>
          </div>
          <div className="blogPageWrap">
            <div className="blogPage">
              <div className="blogPageHead">
                {data.wordpressPage.featured_media && (
                  <Img
                    className="blogPageHead_img"
                    fluid={
                      data.wordpressPage.featured_media.localFile
                        .childImageSharp.fluid
                    }
                  />
                )}
                <div className="blogPageHeadMeta">
                  <span className="blogPageHeadMeta_date">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                      <g>
                        <g>
                          <rect
                            className="cls-1"
                            width="48"
                            height="48"
                            fill="none"
                          />
                        </g>
                        <g className="blogPageHeadMeta_date-icon">
                          <path
                            className="cls-2"
                            d="M16,10H8a2,2,0,0,0-2,2V40a2,2,0,0,0,2,2H40a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2H38"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4px"
                          />
                          <line
                            className="cls-2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4px"
                            x1="22"
                            y1="10"
                            x2="32"
                            y2="10"
                          />
                          <line
                            className="cls-2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4px"
                            x1="16"
                            y1="6"
                            x2="16"
                            y2="14"
                          />
                          <line
                            className="cls-2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4px"
                            x1="32"
                            y1="6"
                            x2="32"
                            y2="14"
                          />
                          <line
                            className="cls-2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4px"
                            x1="6"
                            y1="21"
                            x2="34"
                            y2="21"
                          />
                        </g>
                      </g>
                    </svg>
                    <span className="blogPageHeadMeta_date-txt">
                      published on : {data.wordpressPage.date}
                    </span>
                  </span>
                  {data.wordpressPage.date < data.wordpressPage.modified && (
                    <span className="blogPageHeadMeta_modified">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                      >
                        <g>
                          <g>
                            <rect
                              className="cls-1"
                              width="48"
                              height="48"
                              fill="none"
                            />
                          </g>
                          <g className="blogPageHeadMeta_modified-icon">
                            <polyline
                              className="cls-2"
                              points="17.18 15 8.18 15 8.18 6"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="4px"
                            />
                            <path
                              className="cls-2"
                              d="M10.58,12A18,18,0,1,1,6.23,26.88"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="4px"
                            />
                          </g>
                        </g>
                      </svg>
                      <span className="blogPageHeadMeta_modified-txt">
                        modified on : {data.wordpressPage.modified}
                      </span>
                    </span>
                  )}
                </div>
                <h1 className="blogPageHead_title">
                  {data.wordpressPage.title}
                </h1>
              </div>
              <div
                className="blogPageContent"
                dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
              />

              <Banner />
            </div>
          </div>
        </Layout>
      )
    }}
  />
)
export default Profile
