import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { gsap, Power4, CSSPlugin, ScrollToPlugin } from 'gsap/all'


import "../scss/components/bio.scss"

const Bio = () => {
  gsap.registerPlugin(ScrollToPlugin, CSSPlugin)
  // const plugins = [ CSSPlugin, ScrollToPlugin]

  const toggleMenu = () => {
    const bioInfoMore = document.querySelector('.bioInfoMore')
    const moreBioToggle = document.querySelector('.moreBioToggle');

    if(bioInfoMore.classList.contains('active')) {
      gsap.to(bioInfoMore, 0.5, { ease: Power4.easeOut, height: 0, opacity: 0, overflow: "hidden" })
      gsap.to(window, 0.5, { scrollTo: "#profileBio", ease: Power4.easeOut })
      bioInfoMore.classList.remove('active')
      moreBioToggle.classList.remove('active')
    } else {
      gsap.set(bioInfoMore, { ease: Power4.easeOut, height: 'auto', opacity:1, overflow: "inherit" })
      gsap.from(bioInfoMore, 0.5, { ease: Power4.easeOut, height: 0, opacity: 0, overflow: "hidden" })
      gsap.to(window, 0.5, { scrollTo: { y: "#bioInfoMore", offsetY: 24 }, ease: Power4.easeOut })
      bioInfoMore.classList.add('active')
      moreBioToggle.classList.add('active')
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
            childImageSharp {
              fixed(width: 80, height: 80) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div className="bio">
            <div className="bioHead">
              <Image className="bioHead_img" fixed={data.avatar.childImageSharp.fixed} />
              <div className="bioHead_meta">
                <span className="bioHead_meta-job">Web Designer</span>
                <h4 className="bioHead_meta-name">Kentaro Koga</h4>
              </div>
            </div>
            <div className="bioInfo">
              <div className="bioInfo_txt">
                <p>福岡を拠点にWeb制作をしているフリーランス。</p>
                <p>Web制作 / 写真 / 映像 / 旅 / 筋トレ / コーヒー / レザークラフトをベースに、のんびり生きながら、福岡の田舎でクリエイターチーム「<a href="https://fump.tech/" target="_blank" rel="noopener noreferrer">Fump</a>」として活動しています。</p>
                <p>色んなものを作りながらゆるく生きてます。</p>
              </div>

              <div className="bioInfoMore" id="bioInfoMore">
                <h3 className="bioInfoMore_title">History</h3>
                <div className="bioInfoMore_history">
                  <dl>
                    <dt>2010~</dt>
                    <dd>大学入学：体育会系テニス部に所属</dd>
                  </dl>
                  <dl>
                    <dt>2014~</dt>
                    <dd>卒業後、オーストラリアに語学留学</dd>
                  </dl>
                  <dl>
                    <dt>2015</dt>
                    <dd>３ヶ月ほどニート生活を満喫してインターネット販売会社で働く</dd>
                  </dl>
                  <dl>
                    <dt>2015/12</dt>
                    <dd>高校時代の友人と再会し、共にWeb制作の勉強を開始。</dd>
                  </dl>
                  <dl>
                    <dt>2016らへん</dt>
                    <dd>仕事と筋トレと勉強しながら色々作る。友人と共同で<a href="https://kensuimap.com" target="_blank" rel="noopener noreferrer">懸垂マップ</a>作る。</dd>
                  </dl>
                  <dl>
                    <dt>2017/6~</dt>
                    <dd>フリーランスでWebサイト制作の仕事を開始</dd>
                  </dl>
                  <dl>
                    <dt>2021/9~</dt>
                    <dd>福岡のデザインファーム、株式会社gazにジョイン。</dd>
                  </dl>
                  <dl>
                    <dt>現在</dt>
                    <dd>クリエイティブチーム「<a href="https://fump.tech" target="_blank" rel="noopener noreferrer">Fump</a>」として活動しながら、gazでSTUDIOを用いたサイト制作をしています。</dd>
                  </dl>
                </div>
              </div>
            </div>
            <button className="moreBioToggle" onClick={toggleMenu}>↓</button>
          </div>
        )
      }}
    />
  )
}

export default Bio
